@font-face {
  font-family: ZX-Medium;
  src: url('./assets/fonts/ZX-Medium.ttf');
}

@font-face {
  font-family: Sone;
  src: url('./assets/fonts/Sone-Bold.otf');
}

.am-modal-mask{
  z-index: 1001 !important;
}
.am-modal-wrap{
  z-index: 1001 !important;
}
.am-list-body::after {
    content: '';
    position: absolute;
    background-color: #ffffff !important;

}
.am-accordion-header::after{
  background-color: #f3f3f3!important;
}
.am-accordion-header i{
  width: 6px !important;
}
.am-modal-button{
  font-size: 14px!important;
}
.am-navbar-right{
  -webkit-justify-content: flex-end;
justify-content: flex-end;
font-size: 14px;
margin-right: 15px;
font-weight:normal!important;
}
.am-list-arrow{
      width: 6px!important;
}
.am-tab-bar-tab-title{
  /* color: #d0d0d0 !important; */
  /* color: #d0d0d0 !important; */
  font-size: 10px!important;
}


.am-toast-text {
  border-radius: 8px !important;

  // @keyframes ripple {
  //   0% {
  //     top: 30px;
  //     left: 30px;
  //     width: 0;
  //     height: 0;
  //     opacity: 1;
  //   }

  //   100% {
  //     top: 0px;
  //     left: 0px;
  //     width: 60px;
  //     height: 60px;
  //     opacity: 0;
  //   }
  // }

  // .lds-ripple {
  //   position: relative;
  //   width: 80px !important;
  //   height: 80px !important;
  //   -webkit-transform: translate(-40px, -40px) scale(1) translate(46px, 47px);
  //   transform: translate(-40px, -40px) scale(1) translate(46px, 47px);

  //   div {
  //     box-sizing: content-box;
  //     position: absolute;
  //     border-width: 4px;
  //     border-style: solid;
  //     opacity: 1;
  //     border-radius: 50%;
  //     -webkit-animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  //     animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

  //     &:nth-child(1) {
  //       border-color: #ffe565;
  //     }

  //     &:nth-child(2) {
  //       border-color: #ffc62f;
  //       -webkit-animation-delay: -0.5s;
  //       animation-delay: -0.5s;
  //     }
  //   }

  // }

}

.am-tab-bar-bar::before {
    content: '';
    position: absolute;
    background-color: #f6f6f6!important;}
.animated {
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system,Helvetica Neue,Roboto,Segoe UI,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.am-tabs-horizontal .am-tabs-pane-wrap-inactive {
  visibility: hidden !important;
}

.am-toast > span{
  max-width: 54% !important;
}